import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChatFrom from "./chatform";
import {
  AppLayout,
  RadioGroup,
  RadioGroupProps,
  Toggle,
  ToggleProps,
} from "@cloudscape-design/components";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Grid from "@cloudscape-design/components/grid";
import i18n from "../i18/index";
import { SimpleChat } from "./simpleChat";

const LOCALE = "en";

const Main = () => {
  const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(
    localStorage.getItem("isDarkModeEnable") === "true"
  );
  const [imageUrl, setImageUrl] = useState("");
  const [isLangToggleChecked, setIsLangToggleChecked] = useState(
    localStorage.getItem("appLang") === "zh"
  );
  const { t } = useTranslation();
  const [choice, setChoice] = useState(t("FIRST"));

  useEffect(() => {
    const darkModeClassName = "awsui-dark-mode";

    if (isDarkModeEnabled) {
      if (!document.body.classList.contains(darkModeClassName)) {
        document.body.classList.add(darkModeClassName);
      }
    } else {
      if (document.body.classList.contains(darkModeClassName)) {
        document.body.classList.remove(darkModeClassName);
      }
    }
  }, [isDarkModeEnabled]);

  useEffect(() => {
    const storedLang = localStorage.getItem("appLang") || "en"; // Default to English if not set
    i18n.changeLanguage(storedLang);
  }, []);
  return (
    <AppLayout
      navigationHide
      drawers={
        [
          // {
          //   id: "1",
          //   content: <>abc</>,
          //   trigger: ()=>{},
          // }
        ]
      }
      toolsWidth={800}
      tools={
        imageUrl && (
          <Container>
            <img
              src={imageUrl}
              style={{ objectFit: "contain", width: "100%" }}
            />
          </Container>
        )
      }
      maxContentWidth={1000}
      content={
        <ContentLayout
          header={
            <SpaceBetween size="l">
              <Header
                variant="h1"
                description={t("APP_LAYOUT_SUB_TITLE")}
                actions={
                  <div style={{ width: "280px" }}>
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                      <Toggle
                        checked={isDarkModeEnabled}
                        onChange={(e: { detail: ToggleProps.ChangeDetail }) => {
                          localStorage.setItem(
                            "isDarkModeEnable",
                            e.detail.checked ? "true" : "false"
                          );
                          setIsDarkModeEnabled(e.detail.checked);
                        }}
                      >
                        <b>{t("DARK_MODE_TOGGLE")}</b>
                      </Toggle>
                      {/* <div></div> */}
                      <Toggle
                        checked={isLangToggleChecked}
                        onChange={(e: { detail: ToggleProps.ChangeDetail }) => {
                          const { checked } = e.detail;
                          const selectedLang = checked ? "zh" : "en";
                          i18n.changeLanguage(selectedLang);
                          localStorage.setItem("appLang", selectedLang);
                          setIsLangToggleChecked(checked);
                        }}
                      >
                        <b>{t("LANG_TOGGLE")}</b>
                      </Toggle>
                    </Grid>
                  </div>
                }
              >
                {t("APP_LAYOUT_TITLE")}
              </Header>
            </SpaceBetween>
          }
        >
          <Container
            // fitHeight={true}
            header={<Header variant="h1">{t("CHOICE_CONTAINER_TITLE")}</Header>}
          >
            <RadioGroup
              onChange={(e: { detail: RadioGroupProps.ChangeDetail }) => {
                setChoice(e.detail.value);
              }}
              value={choice}
              items={["FIRST", "SECOND"].map((s) => ({
                value: s,
                label: t(`CHOICE_${s}`),
              }))}
            />
          </Container>
          <br />
          <Container
            fitHeight={true}
            header={
              <Header variant="h1">
                {choice === "FIRST" ? t("CHOICE_FIRST") : t("PAGE_TITLE")}
              </Header>
            }
          >
            {choice === "FIRST" ? (
              <SimpleChat />
            ) : (
              <ChatFrom setImageUrl={setImageUrl} />
            )}
          </Container>
          <br />
          <br />
        </ContentLayout>
      }
    />
  );
};

export default Main;
