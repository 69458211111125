import * as React from "react";
import FileUpload, {
  FileUploadProps,
} from "@cloudscape-design/components/file-upload";
import { useTranslation } from "react-i18next";

export const FileUploader = (props: {
  value: File[];
  isLoading?: boolean;
  handleOnChange: (e: { detail: FileUploadProps.ChangeDetail }) => void;
  uploadButtonText?: string;
  constraintText?: string;
}) => {
  const {
    value,
    handleOnChange,
    isLoading = false,
    uploadButtonText,
    constraintText,
  } = props;
  const { t } = useTranslation();
  return (
    <FileUpload
      onChange={handleOnChange}
      value={value}
      i18nStrings={{
        uploadButtonText: (e) =>
          uploadButtonText ? uploadButtonText : t("FILE_UPLOADER_INFO"),
        dropzoneText: (e) =>
          e ? "Drop files to upload" : "Drop file to upload",
        removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
        limitShowFewer: "Show fewer files",
        limitShowMore: "Show more files",
        errorIconAriaLabel: "Error",
      }}
      showFileLastModified
      showFileSize
      showFileThumbnail
      tokenLimit={3}
      constraintText={
        <>{constraintText ? constraintText : t("FILE_UPLOADER_SUB_INFO")}</>
      }
    />
  );
};
