import { InlineMath, BlockMath } from "react-katex";
import { parsedMessages } from "./index";

export const parseAndRenderAnswer = (answerString: string) => {
  return parsedMessages(answerString).map((obj, index) => {
    const { type, content } = obj;
    switch (type) {
      case "blockMath":
        return <BlockMath key={`block-${index}`}>{content}</BlockMath>;
      case "inlineMath":
        return <InlineMath key={`inline-${index}`}>{content}</InlineMath>;
      case "boldText":
        return <b key={`bold-${index}`}>{content}</b>;
      default:
        return <span key={`text-${index}`}>{content}</span>;
    }
  });
};
