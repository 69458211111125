import * as React from "react";
import Modal from "@cloudscape-design/components/modal";

export default (props: {
  onDismiss: () => void;
  visible: boolean;
  headerText: string;
  children: React.ReactNode;
}) => {
  const { onDismiss, visible, headerText, children } = props;
  return (
    <Modal
      onDismiss={onDismiss}
      visible={visible}
      header={headerText}
      size="max"
    >
      {children}
    </Modal>
  );
};
