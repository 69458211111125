import { useState } from "react";
import { cloneDeep, isEmpty } from "lodash";
import {
  Textarea,
  FileUploadProps,
  Spinner,
  StatusIndicator,
  SpaceBetween,
  Container,
  Link,
} from "@cloudscape-design/components";
import Modal from "../components/Modal";
import Button from "@cloudscape-design/components/button";
import { FileUploader } from "../components/FileUpload";
import { upload } from "../api/chat";
import Grid from "@cloudscape-design/components/grid";
import { Trans, useTranslation } from "react-i18next";
import { wsSendMessage } from "../util/webSocket";
import { parseAndRenderAnswer } from "../util/latexParse";
import Avatar from "@mui/material/Avatar";

const QUESTION_FORMAT =
  " If my question is not math related, stop reading here. Otherwise please use LaTex in this format if necessary: add $ at the begin and end of each latext markup of variable and math conditon, then add $$ at the begin and end of each formula expression, because when I parse $ it will use inlineMath and for $$ it will use blockMath";

export const SimpleChat = () => {
  // Intial state
  const [files, setFiles] = useState<File[]>([]);
  const { t } = useTranslation();
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [question, setQuestion] = useState("");
  const [joinedAnswerHistory, setJoinedAnswerHistory] = useState<string[]>([]);
  const [answer, setAnswer] = useState<String[]>([]); // AI response as iterative char in array
  const [imageUpload, setImageUpload] = useState<{
    isImageUploading: boolean;
    isImagePreviewing: boolean;
    imageUrl: string;
    errorMessage: string;
  }>({
    isImageUploading: false,
    imageUrl: "",
    errorMessage: "",
    isImagePreviewing: false,
  });

  // Handlers
  const handleFileOnChange = async (e: {
    detail: FileUploadProps.ChangeDetail;
  }) => {
    const files = e.detail.value;
    setFiles(files);
    if (!isEmpty(files)) {
      setImageUpload({
        ...imageUpload,
        isImageUploading: true,
      });
      const res = await upload(files);
      setImageUpload({
        ...imageUpload,
        isImageUploading: false,
        imageUrl: res ? res.url : "",
      });
    }
  };

  const sendMessageToAI = () => {
    const { imageUrl } = imageUpload;
    const message = {
      type: imageUrl ? "image" : "",
      imageUrl,
      data: `${question + QUESTION_FORMAT}`,
    };
    const answerCopy = answer.join("");
    setAnswer([]);
    const joinedAnswerHistoryCopy = cloneDeep(joinedAnswerHistory);
    joinedAnswerHistoryCopy.push(answerCopy);
    setJoinedAnswerHistory(joinedAnswerHistoryCopy);
    wsSendMessage({ question: JSON.stringify(message), ws, setWs, setAnswer });
  };

  const renderOpenAIAnswer = (answerMessage: string) => {
    return (
      !isEmpty(answerMessage) && (
        <div>
          <span>
            <Avatar
              sizes="xss"
              alt="ChatGPT"
              src="https://openaiimg.s3.us-east-1.amazonaws.com/download.png"
            />
          </span>
          {parseAndRenderAnswer(answerMessage)}
        </div>
      )
    );
  };

  const renderOpenAIHistory = () => {
    return (
      <>
        {joinedAnswerHistory.map((ans, index) => (
          <>
            <div key={index}>{renderOpenAIAnswer(ans)}</div>
            <br />
          </>
        ))}
      </>
    );
  };

  return (
    <>
      <Modal
        visible={imageUpload.isImagePreviewing}
        headerText="Your uploaded image"
        onDismiss={() =>
          setImageUpload({ ...imageUpload, isImagePreviewing: false })
        }
      >
        <Container>
          <img
            src={imageUpload.imageUrl}
            style={{ objectFit: "contain", width: "100%" }}
          />
        </Container>
      </Modal>
      <SpaceBetween size={"xl"}>
        {renderOpenAIHistory()}
        {renderOpenAIAnswer(answer.join(""))}
        <Textarea
          onChange={({ detail }) => setQuestion(detail.value)}
          value={question}
        />
        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }]}>
          <Grid>
            <FileUploader
              value={files}
              isLoading={imageUpload.isImageUploading}
              handleOnChange={handleFileOnChange}
              uploadButtonText={t("UPLOAD_TEXT")}
              constraintText=" "
            />
            {imageUpload.imageUrl && (
              <>
                <StatusIndicator>
                  {t("UPLOADED_INFO_SIMPLE_CHAT")}
                </StatusIndicator>
                <Trans
                  i18nKey="CLICK_FOR_IMAGE_PREVIEW"
                  components={[
                    <Link
                      onFollow={() =>
                        setImageUpload({
                          ...imageUpload,
                          isImagePreviewing: true,
                        })
                      }
                    />,
                  ]}
                />
              </>
            )}
          </Grid>

          {imageUpload.isImageUploading && (
            <Spinner variant="normal" size="normal" />
          )}
          <Button variant="primary" onClick={sendMessageToAI}>
            {t("SUBMIT_QUESTION")}
          </Button>
        </Grid>
      </SpaceBetween>
    </>
  );
};
