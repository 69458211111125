import { useState, useEffect } from "react";
import { cloneDeep, isEmpty, set } from "lodash";
import { useTranslation, Trans } from "react-i18next";
import {
  Checkbox,
  Container,
  FileUploadProps,
} from "@cloudscape-design/components";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Grid from "@cloudscape-design/components/grid";
import { upload, WEB_SOCKET_URL } from "../api/chat";
import { FileUploader } from "../components/FileUpload";
import Spinner from "@cloudscape-design/components/spinner";
import Link from "@cloudscape-design/components/link";
import StatusIndicator from "@cloudscape-design/components/status-indicator";
import Modal from "../components/Modal";
import Tabs from "@cloudscape-design/components/tabs";
import { UpdatePaths } from "../util";
import {
  PAGE_STATUS,
  QUESTION_STATUS,
  SubQuestionConfig,
  QuestionConfig,
  PageConfig,
  OnSuccessCallBack,
} from "./common.types";
import SubQuestions from "./subQuestions";

// import { SubQuestions } from "./subQuestions";

const Chatfrom = (props: { setImageUrl: (url: string) => void }) => {
  const { t } = useTranslation();
  const questionOptioinsContent = [
    t("CUSTOM_ANSWER_OPTION_1"),
    t("CUSTOM_ANSWER_OPTION_2"),
  ];
  const [page, setPage] = useState<PageConfig>({
    pageStatus: PAGE_STATUS.BLANK,
    image: {
      isImagePreviewing: false,
      isImageUploading: false,
      imageUrl: "",
      errorMessage: "",
    },
    questions: [],
    questionOptions: [true, true, false],
  });

  const updatePage = (
    updates: UpdatePaths<PageConfig>,
    iterateUpdate?: (currentPage: PageConfig) => UpdatePaths<PageConfig>
  ) => {
    if (iterateUpdate) {
      setPage((currentPage) => {
        // Create a shallow copy of currentPage
        let newState = cloneDeep(currentPage);
        const curUpdates = iterateUpdate(cloneDeep(currentPage));
        // Apply each update with lodash's `set` function
        Object.entries(curUpdates).forEach(([path, value]) => {
          set(newState, path, value);
        });

        return newState;
      });
    } else {
      setPage((currentPage) => {
        // Create a shallow copy of currentPage
        let newState = { ...currentPage };

        // Apply each update with lodash's `set` function
        Object.entries(updates).forEach(([path, value]) => {
          set(newState, path, value);
        });

        return newState;
      });
    }
  };

  const [imageUpload, setImageUpload] = useState<{
    isImageUploading: boolean;
    imageUrl: string;
    errorMessage: string;
  }>({
    isImageUploading: false,
    imageUrl: "",
    errorMessage: "",
  });
  const [text, setText] = useState("");
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [messages, setMessages] = useState<String[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  // console.log(page);

  useEffect(() => {
    if (imageUpload.imageUrl) {
      sendMessage({ pageStatus: PAGE_STATUS.IMAGE_BACKGROUND_ASKING });
    }
    console.log("imageUpload.imageUrl", imageUpload.imageUrl);
  }, [imageUpload.imageUrl]);

  useEffect(() => {
    const { pageStatus } = page;
    if (pageStatus === PAGE_STATUS.IMAGE_BACKGROUND_ASKING) {
      try {
        const rawQuestions: { [questionName: string]: string[] }[] = JSON.parse(
          messages.join("")
        );
        const questions = rawQuestions.map((rawQuestion) => {
          if (Object.keys(rawQuestion).length > 1) {
            throw new Error(
              `Error parsing due to multiple object in one question: ${rawQuestion} when parsing ${rawQuestions}`
            );
          }
          const rawQuestionName = Object.keys(rawQuestion)[0];
          const subQuestionNameList = rawQuestion[rawQuestionName];
          const question: QuestionConfig = {};
          question[rawQuestionName] = {
            subQuestions: [],
            customQuestion: false,
            question: "",
            context: [],
            answer: [],
          };
          if (!isEmpty(subQuestionNameList)) {
            subQuestionNameList.forEach((subQuestionName) => {
              const subQuestion: Record<string, SubQuestionConfig> = {
                [subQuestionName]: {
                  answer: [],
                  customQuestion: false,
                  question: "",
                  context: [],
                  status: QUESTION_STATUS.BLANK,
                },
              };
              question[rawQuestionName].subQuestions.push(subQuestion);
            });
          } else {
            question[rawQuestionName].status = QUESTION_STATUS.BLANK;
          }
          return question;
        });

        updatePage({
          questions,
          pageStatus: PAGE_STATUS.IMAGE_BACKGROUND_ANSWERED,
        });

        updatePage({ pageStatus: PAGE_STATUS.IMAGE_BACKGROUND_ANSWERED });
        console.log("convert to array from ", rawQuestions, "to", questions);
      } catch (e) {
        console.log(
          "messages is not ready for array parse",
          messages.join(""),
          e
        );
      }
    }
  }, [messages]);

  // TODO: Re-use createWebSocketConnection from util.ts
  const createWebSocketConnection = (onSuccess?: OnSuccessCallBack) => {
    const socket = new WebSocket(WEB_SOCKET_URL);

    socket.addEventListener("open", (event) => {
      console.log("Connected to WS Server");
    });

    socket.addEventListener("message", (event) => {
      const incomingMessage: string = event.data;
      // console.log("run message", incomingMessage);
      if (onSuccess) {
        onSuccess(incomingMessage);
      } else {
        setMessages((prevMessages) => [...prevMessages, incomingMessage]);
      }
    });

    socket.addEventListener("close", (event) => {
      console.log("Disconnected from WS Server");
    });

    return socket;
  };

  const getFinalQuestionText = (questionCustomQuestion?: string): string => {
    if (page.questionOptions[1]) {
      return (
        questionCustomQuestion +
        ". Can you answer in two parts. The first part is just the answer without any analysis (If the question is for choices, then just provide the choice like A or B or C ...). " +
        "The second part is to start with word `Solution details:` and then give an explanation assuming I have no background knowledge of the subject."
      );
    } else {
      return `${questionCustomQuestion}. Can you please provide a simple answer without any analysis? If the question is for choices, then just provide the choice like A or B or C ...`;
    }
  };

  // Function to send a message to the server
  // explictly pass pageStatus as setPageStatus is async
  const sendMessage = (props: {
    pageStatus: PAGE_STATUS;
    questionTitle?: string;
    onSuccess?: () => void;
  }) => {
    const { pageStatus } = props;
    updatePage({ pageStatus });
    if (isEmpty(imageUpload.imageUrl)) {
      throw new Error("image has not been uploaded");
    }

    const message = {
      type: "image",
      imageUrl: imageUpload.imageUrl,
      data: "",
    };

    let messageString: string = "";

    switch (pageStatus) {
      case PAGE_STATUS.IMAGE_BACKGROUND_ASKING:
        message.data =
          "Can you check in this image and find all questions index which can be either digit number or characters." +
          "Put them into an array of object, each object indicates a question and its subquestions if there is any, let me explain the object stucture in examples." +
          "Example 1, if there is two questions a and b, and there is no subquestions. You should return [{a: []}, {b: []}]." +
          "Example 2, if there is two questions a and b, a doesn't have subquestions but b has subquestions i and ii. You should return [{a:[]},{b:[i,ii]}]" +
          "Plese only return the array result, not any other characters. If a question doesn't have any index, then just return [{question: []}]";
        messageString = JSON.stringify(message);
        setMessages([]);
        wsSendMessage(messageString);
        return;
      default:
        throw new Error("Invoking send message in un-recognized status");
    }
  };

  const sendMessageToAI = (props: {
    questionTitle: string;
    questionCustomQuestion?: string;
    onSuccess: OnSuccessCallBack; // update question status and store res in state
  }) => {
    const { questionTitle, onSuccess, questionCustomQuestion } = props;
    if (isEmpty(imageUpload.imageUrl)) {
      throw new Error("image has not been uploaded");
    }

    const message = {
      type: "image",
      imageUrl: imageUpload.imageUrl,
      data: "",
    };

    let messageString: string = "";

    message.data =
      `For study material and purpose (if that's an exam/assignment, it is a very old one which is not active), direct response in LaTeX for only section ${questionTitle} request: ` +
      `${getFinalQuestionText(questionCustomQuestion)}` +
      "Format: Please add $ at the begin and end of each latext markup of variable and math conditon, then add $$ at the begin and end of each formula expression, because when I parse $ it will use inlineMath and for $$ it will use blockMath. " +
      `Provide response for ${questionTitle} only. No introductory text, please. ${
        localStorage.getItem("appLang") === "zh"
          ? "Please translate the answer into Chinese"
          : ""
      }`;

    // message.data =
    //   `I want to only ask question for section ${questionTitle} in the image.` +
    //   (!isEmpty(questionCustomQuestion)
    //     ? `Here is my question: ${getFinalQuestionText(
    //         questionCustomQuestion
    //       )}.`
    //     : "") +
    //   "Please add $ at the begin and end of each latext markup of variable and math conditon, then add $$ at the begin and end of each formula expression, " +
    //   "because when I parse $ it will use inlineMath and for $$ it will use blockMath";

    console.log("**************************************", message.data);
    messageString = JSON.stringify(message);
    setMessages([]);
    wsSendMessage(messageString, onSuccess);
  };

  const wsSendMessage = (message: string, onSuccess?: OnSuccessCallBack) => {
    if (ws && ws.readyState !== WebSocket.CLOSED) {
      ws.close();
    }
    const newSocket = createWebSocketConnection(onSuccess);
    setWs(newSocket);
    // Ensure the socket is open before sending the message
    newSocket.addEventListener("open", () => {
      newSocket.send(message);
    });
  };

  const handleFileOnChange = async (e: {
    detail: FileUploadProps.ChangeDetail;
  }) => {
    const files = e.detail.value;
    setFiles(files);
    if (!isEmpty(files)) {
      setImageUpload({
        ...imageUpload,
        isImageUploading: true,
      });
      const res = await upload(files);
      setImageUpload({
        ...imageUpload,
        isImageUploading: false,
        imageUrl: res ? res.url : "",
      });
      props.setImageUrl(res ? res.url : "");
    }
  };

  const renderQuestionContent = (
    q: QuestionConfig,
    index: number
  ): JSX.Element => {
    return (
      <SubQuestions
        question={q}
        updatePage={updatePage}
        index={index}
        sendMessageToAI={sendMessageToAI}
      />
    );
  };

  const renderQuestionList = () => {
    const tabsObject: { label: string; id: string; content: JSX.Element }[] =
      page.questions.map((q, index) => ({
        id: index.toString(),
        label: `Question-${Object.keys(q)[0]}`,
        content: renderQuestionContent(q, index),
      }));

    return (
      <>
        <br />
        <SpaceBetween direction="horizontal" size="xs">
          {questionOptioinsContent.map((questionOptionContent, index) => (
            <Checkbox
              key={index}
              onChange={({ detail }) => {
                const questionOptions = cloneDeep(page.questionOptions);
                questionOptions[index] = detail.checked;
                updatePage({ questionOptions });
              }}
              checked={page.questionOptions[index]}
            >
              {questionOptionContent}
            </Checkbox>
          ))}
        </SpaceBetween>

        <br />
        <Tabs tabs={tabsObject} />

        {/* <h3>Choose the question you want to ask in the image</h3> */}
        {/* <RadioGroup
          onChange={({ detail }) =>
            setQuestionList({
              ...questionList,
              q2: {
                ...questionList.q2,
                selectedIndex: Number(detail.value),
              },
            })
          }
          value={
            questionList.q2.selectedIndex !== null // 0 is a falcy value
              ? questionList.q2.selectedIndex.toString()
              : null
          }
          items={questionList.q2.content.map((q, index) => ({
            value: index.toString(),
            label: q,
          }))}
        /> */}
      </>
    );
  };

  type ParsedMessageType = "blockMath" | "inlineMath" | "text";

  const parsedMessages = (): { type: ParsedMessageType; content: string }[] => {
    // This regex looks for $$ for block math and $ for inline math
    const regex =
      /(\$\$[^$]+?\$\$|\$[^$]+?\$)|\\\([^$]+?\\\)|\\\\([^$]+?\\\\)/g;
    const parts = messages.join("").split(regex);

    return parts.map((part) => {
      if (part.startsWith("$$") && part.endsWith("$$")) {
        // It's a block math expression
        return { type: "blockMath", content: part.slice(2, -2) };
      } else if (part.startsWith("$") && part.endsWith("$")) {
        // It's an inline math expression
        return { type: "inlineMath", content: part.slice(1, -1) };
      } else if (part.startsWith("/(") && part.endsWith("/)")) {
        return { type: "inlineMath", content: part };
      } else if (part.startsWith("//(") && part.endsWith("//)")) {
        return { type: "inlineMath", content: part.slice(1, -1) };
      } else {
        // It's regular text
        return { type: "text", content: part };
      }
    });
  };

  const {
    pageStatus,
    image: { isImagePreviewing },
  } = page;

  return (
    <>
      <Modal
        visible={isImagePreviewing}
        headerText="Preview your uploaded image"
        onDismiss={() => updatePage({ "image.isImagePreviewing": false })}
      >
        <Container>
          <img
            src={imageUpload.imageUrl}
            style={{ objectFit: "contain", width: "100%" }}
          />
        </Container>
      </Modal>
      <br />
      <Grid gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
        <div>
          <FileUploader
            value={files}
            isLoading={imageUpload.isImageUploading}
            handleOnChange={handleFileOnChange}
          />
        </div>
        <div></div>
      </Grid>
      <br />
      {imageUpload.isImageUploading && (
        <Spinner variant="normal" size="normal" />
      )}
      {!imageUpload.isImageUploading && pageStatus > PAGE_STATUS.BLANK && (
        <>
          <StatusIndicator>{t("UPLOADED_INFO")}</StatusIndicator> &nbsp;
          <Trans
            i18nKey="CLICK_FOR_IMAGE_PREVIEW"
            components={[
              <Link
                onFollow={() => updatePage({ "image.isImagePreviewing": true })}
              />,
            ]}
          />
        </>
      )}
      <br />
      {pageStatus == 1 ? (
        <Spinner size="normal" />
      ) : (
        pageStatus > 1 && (
          <>
            {renderQuestionList()}
            <br />
            <br />
            {/* <Button
              loading={pageStatus === QUESTION_STATUS.ASKING}
              variant="primary"
              onClick={() => {
                updatePage({ pageStatus: PAGE_STATUS.IMAGE_ASKING });
                sendMessage(PAGE_STATUS.IMAGE_ASKING);
              }}
            >
              {" "}
              Ask chatGPT
            </Button> */}
            <br />
            <br />
            {/* {pageStatus === PAGE_STATUS.IMAGE_ANSWERED &&
              parsedMessages().map((obj, index) => {
                const { type, content } = obj;
                if (type === "blockMath") {
                  // Block math is rendered as a separate block
                  return (
                    <BlockMath key={`block-${index}`}>{content}</BlockMath>
                  );
                } else if (type === "inlineMath") {
                  // Inline math is rendered in-line with the text
                  return (
                    <InlineMath key={`inline-${index}`}>{content}</InlineMath>
                  );
                } else {
                  // Text is just rendered as is
                  return <span key={`text-${index}`}>{content}</span>;
                }
              })} */}
            {/* <p>{messages.join("")}</p> */}
          </>
        )
      )}
    </>
  );
};

export default Chatfrom;
