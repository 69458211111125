export enum PAGE_STATUS {
  BLANK = 0,
  IMAGE_BACKGROUND_ASKING = 1,
  IMAGE_BACKGROUND_ANSWERED = 2,
}

export enum QUESTION_STATUS {
  BLANK = 0,
  ASKING = 1,
  ANSWERED = 2,
}

export type ImageConfig = {
  isImagePreviewing: boolean;
  isImageUploading: boolean;
  imageUrl: string;
  errorMessage: string;
};

export type SubQuestionConfig = {
  answer: string[];
  question: string;
  context: [];
  customQuestion: boolean;
  status: QUESTION_STATUS;
};

export type QuestionConfig = Record<
  string,
  {
    subQuestions: Record<string, SubQuestionConfig>[];
    answer: string[];
    question: string;
    context: [];
    customQuestion: boolean;
    status?: QUESTION_STATUS;
  }
>;

export type PageConfig = {
  pageStatus: PAGE_STATUS;
  image: ImageConfig;
  questions: QuestionConfig[] | [];
  questionOptions: boolean[];
};

export type OnSuccessCallBack = (message: string) => void;

// Add status for each question
