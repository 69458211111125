import React from "react";
import Textarea from "@cloudscape-design/components/textarea";

const TextArea = (props: {
  placeholder?: string;
  rows?: number;
  disabled?: boolean;
  value: string;
  handleOnChange: (v: string) => void;
}) => {
  const {
    value,
    handleOnChange,
    rows,
    disabled = false,
    placeholder = "",
  } = props;
  return (
    <Textarea
      {...(rows && { rows })}
      autoComplete
      disabled={disabled}
      onChange={({ detail }) => handleOnChange(detail.value)}
      value={value}
      placeholder={placeholder}
    />
  );
};

export default TextArea;
