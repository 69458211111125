import { cloneDeep, isEmpty } from "lodash";
import { InlineMath, BlockMath } from "react-katex";
import { useTranslation } from "react-i18next";
import {
  OnSuccessCallBack,
  PageConfig,
  QUESTION_STATUS,
  QuestionConfig,
  SubQuestionConfig,
} from "./common.types";
import ExpandableSection from "@cloudscape-design/components/expandable-section";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import { Checkbox } from "@cloudscape-design/components";
import { UpdatePaths, parsedMessages } from "../util";
import TextArea from "../components/TextArea";

export const renderParsedMessage = (messages: string[]) => {
  const messageString = messages.join("");
  console.log(JSON.stringify(messageString));
  try {
    const answerAndSolution = messageString.split("Solution details:");
    return (
      <>
        <div>
          <span>
            <b>Answer: </b>
          </span>
          {renderAnswerOrSolution(answerAndSolution[0])}
        </div>
        <br />
        {renderAnswerOrSolution(answerAndSolution[1])}
      </>
    );
  } catch (e) {
    console.log(e, "and message string is", messageString);
    return <></>;
  }
};

const renderAnswerOrSolution = (messages: string) => {
  return parsedMessages(messages).map((obj, index) => {
    const { type, content } = obj;
    switch (type) {
      case "blockMath":
        return <BlockMath key={`block-${index}`}>{content}</BlockMath>;
      case "inlineMath":
        return <InlineMath key={`inline-${index}`}>{content}</InlineMath>;
      case "boldText":
        return <b key={`bold-${index}`}>{content}</b>;
      default:
        return <span key={`text-${index}`}>{content}</span>;
    }
  });
};

const SubQuestions = (props: {
  question: QuestionConfig;
  index: number;
  updatePage: (
    updates: UpdatePaths<PageConfig>,
    iterateUpdate?: (currentPage: PageConfig) => UpdatePaths<PageConfig>
  ) => void;
  sendMessageToAI: (props: {
    questionTitle: string;
    questionCustomQuestion?: string;
    onSuccess: OnSuccessCallBack; // update question status and store res in state
  }) => void;
}) => {
  const { t } = useTranslation();
  const CONSTANTS = {
    SUB_QUESTION_CUSTOM_PROMPT: t("CUSTOM_QUESTION_CHECKBOX"),
  };
  const { question, updatePage, index, sendMessageToAI } = props;
  const questionTitle = Object.keys(question)[0];
  const questionObj = Object.values(question)[0];
  const subQuestions = questionObj.subQuestions;

  const renderHeader = (
    handleOnClick: () => void,
    loading: boolean = false
  ) => {
    return (
      <Button variant="primary" loading={loading} onClick={handleOnClick}>
        {t("ASK")}
      </Button>
    );
  };

  const handleAskQuestion = () => {
    updatePage({
      [`questions[${index}][${questionTitle}].status`]: QUESTION_STATUS.ASKING,
    });

    let questionCustomQuestion: string = "";
    if (questionObj.customQuestion) {
      questionCustomQuestion = questionObj.question;
    }
    console.log("run sendMessageToAI");
    sendMessageToAI({
      questionTitle,
      questionCustomQuestion,
      onSuccess: (message) => {
        updatePage({}, (currentPage: PageConfig): UpdatePaths<PageConfig> => {
          const questionObj = Object.values(currentPage.questions[index])[0];
          const questionObjClone = cloneDeep(questionObj);
          questionObjClone.answer = [...cloneDeep(questionObj.answer), message];
          questionObjClone.status = QUESTION_STATUS.ANSWERED;
          return {
            [`questions[${index}]`]: { [questionTitle]: questionObjClone }, // update path is `questions[index]`
          };
        });
      },
    });
  };

  const handleAskSubQuestion = (props: {
    subQuestionObject: Record<string, SubQuestionConfig>;
    subQuestionIndex: number;
  }) => {
    const { subQuestionObject, subQuestionIndex } = props;
    const subQuestionTitle = Object.keys(subQuestionObject)[0];
    const subQuestion = Object.values(subQuestionObject)[0];
    const subQuestionClone = cloneDeep(subQuestion);
    subQuestionClone.status = QUESTION_STATUS.ASKING;
    updatePage({
      [`questions[${index}][${questionTitle}].subQuestions[${subQuestionIndex}]`]:
        {
          [subQuestionTitle]: subQuestionClone,
        },
    });

    sendMessageToAI({
      questionTitle: `${questionTitle} and only for subquestion ${subQuestionTitle}`,
      questionCustomQuestion: subQuestion.customQuestion
        ? subQuestion.question
        : "",
      onSuccess: (message) => {
        updatePage({}, (currentPage: PageConfig): UpdatePaths<PageConfig> => {
          const subQuestionObj = Object.values(
            Object.values(currentPage.questions[index])[0].subQuestions[
              subQuestionIndex
            ]
          )[0];
          const subQuestionObjClone = cloneDeep(subQuestionObj);
          subQuestionObjClone.answer = [
            ...cloneDeep(subQuestionObjClone.answer),
            message,
          ];
          subQuestionObjClone.status = QUESTION_STATUS.ANSWERED;
          return {
            [`questions[${index}][${questionTitle}].subQuestions[${subQuestionIndex}]`]:
              {
                [subQuestionTitle]: subQuestionObjClone,
              },
          };
        });
      },
    });
  };

  if (isEmpty(subQuestions)) {
    return (
      <ExpandableSection
        variant="container"
        headerText={` `}
        headerActions={renderHeader(
          handleAskQuestion,
          questionObj.status === QUESTION_STATUS.ASKING
        )}
        defaultExpanded
      >
        <Checkbox
          onChange={({ detail }) => {
            const questionObjClone = cloneDeep(questionObj);
            questionObjClone.customQuestion = detail.checked;
            updatePage({
              [`questions[${index}]`]: { [questionTitle]: questionObjClone }, // update path is `questions[index]`
            });
          }}
          checked={questionObj.customQuestion}
        >
          {CONSTANTS.SUB_QUESTION_CUSTOM_PROMPT}
        </Checkbox>
        {questionObj.customQuestion && (
          <>
            <br />
            <TextArea
              rows={2}
              handleOnChange={(t: string) => {
                const questionObjClone = cloneDeep(questionObj);
                questionObjClone.question = t;
                updatePage({
                  [`questions[${index}]`]: {
                    [questionTitle]: questionObjClone,
                  },
                });
              }}
              value={questionObj.question}
              placeholder={t("CUSTOM_QUESTION_TEXTAREA_PLACEHOLDER")}
            />
          </>
        )}
        <br />
        <br />
        {questionObj.status === QUESTION_STATUS.ANSWERED &&
          renderParsedMessage(questionObj.answer)}
      </ExpandableSection>
    );
  } else {
    return (
      <SpaceBetween direction="vertical" size="l">
        {subQuestions.map((subQuestionObject, i) => {
          const subQuestionTitle = Object.keys(subQuestionObject)[0];
          const subQuestion = Object.values(subQuestionObject)[0];
          return (
            <ExpandableSection
              key={i}
              variant="container"
              headerText={`${subQuestionTitle}`}
              headerActions={renderHeader(() =>
                handleAskSubQuestion({ subQuestionObject, subQuestionIndex: i })
              )}
              defaultExpanded
            >
              <Checkbox
                onChange={({ detail }) => {
                  const subQuestionClone = cloneDeep(subQuestion);
                  subQuestionClone.customQuestion = detail.checked;
                  updatePage({
                    [`questions[${index}].${questionTitle}.subQuestions[${i}]`]:
                      {
                        [subQuestionTitle]: subQuestionClone,
                      },
                  });
                }}
                checked={subQuestion.customQuestion}
              >
                {CONSTANTS.SUB_QUESTION_CUSTOM_PROMPT}
              </Checkbox>
              {subQuestion.customQuestion && (
                <>
                  <br />
                  <TextArea
                    rows={2}
                    handleOnChange={(t: string) => {
                      const subQuestionClone = cloneDeep(subQuestion);
                      subQuestionClone.question = t;
                      updatePage({
                        [`questions[${index}].${questionTitle}.subQuestions[${i}]`]:
                          {
                            [subQuestionTitle]: subQuestionClone,
                          },
                      });
                    }}
                    value={questionObj.question}
                    placeholder="I want to ..."
                  />
                </>
              )}
              <br />
              <br />
              {subQuestion.status === QUESTION_STATUS.ANSWERED &&
                renderParsedMessage(subQuestion.answer)}
            </ExpandableSection>
          );
        })}
      </SpaceBetween>
    );
  }
};

export default SubQuestions;
