import { OnSuccessCallBack } from "../pages/common.types";
import { WEB_SOCKET_URL } from "../api/chat";

export const createWebSocketConnection = (props: {
  onSuccess?: OnSuccessCallBack;
  setAnswer: (cb: any) => void;
}) => {
  const { onSuccess, setAnswer } = props;
  const socket = new WebSocket(WEB_SOCKET_URL);

  socket.addEventListener("open", (event) => {
    console.log("Connected to WS Server");
  });

  socket.addEventListener("message", (event) => {
    const incomingMessage: string = event.data;
    // console.log("run message", incomingMessage);
    if (onSuccess) {
      onSuccess(incomingMessage);
    } else {
      setAnswer((prevMessages: string[]) => [...prevMessages, incomingMessage]);
    }
  });

  socket.addEventListener("close", (event) => {
    console.log("Disconnected from WS Server");
  });

  return socket;
};

export const wsSendMessage = (props: {
  question: string;
  onSuccess?: OnSuccessCallBack;
  ws: WebSocket | null;
  setWs: (ws: WebSocket) => void;
  setAnswer: (cb: any) => void;
}) => {
  const { ws, setWs, setAnswer, question, onSuccess } = props;
  if (ws && ws.readyState !== WebSocket.CLOSED) {
    ws.close();
  }
  const newSocket = createWebSocketConnection({ onSuccess, setAnswer });
  setWs(newSocket);
  // Ensure the socket is open before sending the message
  newSocket.addEventListener("open", () => {
    newSocket.send(question);
  });
};
