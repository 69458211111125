const BASE_URL = "https://americanmetaverseconsultant.com";
export const WEB_SOCKET_URL = "wss://americanmetaverseconsultant.com/ws";

// const BASE_URL = "http://localhost:4000";
// export const WEB_SOCKET_URL = `ws://localhost:4000`;

export const simpleChat = async (input: string) => {
  try {
    const response = await fetch(`${BASE_URL}/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization or other headers might be necessary
      },
      body: JSON.stringify({ prompt: input }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error sending message:", error);
  }
};

export const upload = async (
  files: File[]
): Promise<{ url: string } | void> => {
  const formData = new FormData();
  console.log(formData);
  formData.append("image", files[0]);
  try {
    const response = await fetch(`${BASE_URL}/upload`, {
      method: "POST",
      body: formData, // send the file as FormData
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};
