type Primitive = string | number | boolean | bigint | symbol | undefined | null;

type DeepKeys<T, ParentKey extends string = ""> = T extends Primitive
  ? never
  : {
      [K in keyof T]: K extends string | number
        ? T[K] extends Primitive | Array<any>
          ? `${ParentKey}${K & string}`
          : DeepKeys<T[K], `${ParentKey}${K & string}.`>
        : never;
    }[keyof T];

type AtPath<T, Path extends string> = Path extends `${infer Key}.${infer Rest}`
  ? Key extends keyof T
    ? AtPath<T[Key], Rest>
    : never
  : Path extends keyof T
  ? T[Path]
  : never;

/**
 * T is the state type to be updated
 */
export type UpdatePaths<T> = {
  [P in DeepKeys<T>]?: AtPath<T, P>;
};

type ParsedMessageType =
  | "blockMath"
  | "inlineMath"
  | "text"
  | "boldText"
  | "newLine";

/**
 * Parse answer in order to render LATEX
 */
export const parsedMessages = (
  messageString: string
): { type: ParsedMessageType; content: string }[] => {
  // This regex looks for $$ for block math and $ for inline math
  const regex =
    /(\$\$(?:[^$]|(?<=\\)\$)*\$\$|\$(?:[^$]|(?<=\\)\$)*\$)|\\\(.*?\\\)|\\begin\{[^}]*\}.*?\\end\{[^}]*\}/gs;

  // const regex =
  //   /(\$\$(?:[^$]|(?<=\\)\$)*\$\$|\$(?:[^$]|(?<=\\)\$)*\$)|\\\(.*?\\\)|\\begin\{[^}]*\}.*?\\end\{[^}]*\}|\\textbf\{([^}]+)\}/gs;
  // console.log("messages to be parsed is", messageString);
  const parts = messageString.split(regex);
  // console.log("parts", parts, messages.join(""));

  return parts.map((part) => {
    if (part) {
      if (part.startsWith("$$") && part.endsWith("$$")) {
        // It's a block math expression
        return { type: "blockMath", content: part.slice(2, -2).trim() };
      } else if (part.startsWith("$") && part.endsWith("$")) {
        // It's an inline math expression
        return { type: "inlineMath", content: part.slice(1, -1).trim() };
      } else if (part.startsWith("\\(") && part.endsWith("\\)")) {
        return { type: "inlineMath", content: part.slice(3, -3).trim() };
      } else if (part.match(/\\begin\{[^}]*\}.*?\\end\{[^}]*\}/s)) {
        return { type: "blockMath", content: part.trim() }; // Treat LaTeX environments as block math
      } else {
        // regular text as fallback
        return { type: "text", content: part };
      }
    } else {
      return { type: "text", content: "" };
    }
  });
};

/**
 * New parse answer in order to render LATEX
 */
export const parseMessages = (
  messages: string[]
): { type: ParsedMessageType; content: string }[] => {
  const regex = /(\$\$[^$]+?\$\$|\$[^$]+?\$)|\\\([^$]+?\\\)/g;
  const messageString = messages.join("");
  const matches = messageString.match(regex) || [];
  const parts: { type: ParsedMessageType; content: string }[] = [];

  let lastIndex = 0;
  matches.forEach((match) => {
    const matchIndex = messageString.indexOf(match, lastIndex);
    const textPart = messageString.substring(lastIndex, matchIndex);

    if (textPart) {
      parts.push({ type: "text", content: textPart });
    }

    if (match.startsWith("$$") && match.endsWith("$$")) {
      parts.push({ type: "blockMath", content: match.slice(2, -2) });
    } else if (match.startsWith("$") && match.endsWith("$")) {
      parts.push({ type: "inlineMath", content: match.slice(1, -1) });
    } else if (match.startsWith("\\(") && match.endsWith("\\)")) {
      parts.push({ type: "inlineMath", content: match });
    }

    lastIndex = matchIndex + match.length;
  });

  // Handle any remaining text after the last match
  const remainingText = messageString.substring(lastIndex);
  if (remainingText) {
    parts.push({ type: "text", content: remainingText });
  }

  return parts;
};
